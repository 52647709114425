<template>
    <div id="registerComplete" class="bg-tertiary-b min-h-screen">
        <div class="pt-16 min-h-screen w-full flex items-center justify-center">
            <div class="h-full w-full relative text-center max-w-sm mx-auto" role="region" aria-live="polite">
                <transition name="portal-fade" mode="out-in">
                    <!-- loading -->
                    <div v-if="loading" class="relative w-full">
                        <LoadingSpinner :diameterRem="4" />
                        <p class="sr-only">{{ strings.loading }}</p>
                    </div>
                    <!-- already registered -->
                    <div v-else-if="regProcessState == 'DONE'" class="w-full px-4 py-12 relative lg:pt-0">
                        <h1 class="h2 display-text mb-6">{{ strings["already-reg"] }}</h1>
                        <a href="/login" class="btn bg-black text-white">{{ strings.login }}</a>
                    </div>
                    <!-- ask for password -->
                    <div v-else-if="['ALREADY_VALIDATED', 'VALIDATED'].includes(regProcessState)" class="w-full px-4 py-12 relative lg:pt-0">
                        <h1 class="h2 display-text mb-6">{{ strings.thankyou }}</h1>
                        <p class="mb-5 text-xl">{{ strings["choose-pw"] }}</p>
                        <passwordInput @valid-password-input="completeRegistration" cypressLabel="reg-complete-pw" />
                    </div>
                    <!-- validation link expired -->
                    <div v-else class="w-full px-4 py-12 lg:pt-0 relative">
                        <h1 class="h2 display-text">{{ strings.expired }}</h1>
                        <p class="mt-6">
                            {{ strings["re-signup"] }}
                        </p>
                        <router-link class="btn bg-black text-white mt-4" :to="{ name: 'register' }">{{ strings.signup }}</router-link>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/ui/loadingSpinner";
import PasswordInput from "@/components/auth/passwordInput";
import text from "./registerCompleteText";

export default {
    name: "RegisterComplete",
    components: {
        PasswordInput,
        LoadingSpinner,
    },
    data() {
        return {
            regProcessState: "INVALID",
            email: "",
            registrationSource: "",
            loading: false,
            password: null,
            passwordStrongEnough: false,
            errorFromTextbox: null,
            strings: text,
        };
    },
    created() {
        this.loading = true;
        this.$api.auth
            .check_registration(this.$route.params.token)
            .then((response) => {
                this.regProcessState = response.data.state;
                this.email = response.data.email;
                // We'll use this information later when users need to be directed to different places
                // this.registrationSource = response.data.registration_source;
                // if (this.regProcessState === "VALIDATED") this.$gtm.trackEvent({ event: "CE - Email Verified" });

                this.loading = false;
            })
            .catch(() => {
                console.info("registration error");
                this.loading = false;
            });
    },
    methods: {
        completeRegistration(validPasswordValue) {
            this.loading = true;
            // eslint-disable-next-line global-require
            const bcrypt = require("bcryptjs");
            const salt = `$2a$10$KdtO7gxB1VPlolHaUBLZUu${this.email}`,
                password_hash = bcrypt.hashSync(validPasswordValue, salt);

            this.$api.auth
                .complete_registration(this.$route.params.token, password_hash)
                .then(() => {
                    // Right now we aren't sending users anywhere after registering - we'll show a link to admin instead
                    this.loading = false;
                    this.$router.push({ name: "welcome" });
                })
                .catch(() => {
                    this.$toastSwal.fire({
                        title: "There was a problem with the password input.",
                    });
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.login-error-holder {
    min-height: 2.2rem;
}
</style>
