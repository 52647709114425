<template>
    <div id="passwordReset" class="bg-tertiary-b h-full min-h-screen">
        <div class="pt-16 min-h-screen w-full flex items-center justify-center">
            <div class="h-full w-full relative text-center max-w-sm mx-auto holder" role="region" aria-live="polite">
                <transition name="portal-fade" mode="out-in">
                    <!-- loading -->
                    <div v-if="loading" class="relative w-full h-full holder flex flex-col justify-center">
                        <LoadingSpinner :diameterRem="4" />
                        <p class="sr-only">{{ strings.loading }}</p>
                    </div>
                    <!-- reset password complete -->
                    <div v-else-if="completed">
                        <h1 class="h1 mb-6">{{ strings.success }}</h1>
                        <p class="px-4 mb-8">{{ strings["success-exp"] }}</p>
                        <a href="/home" data-cy="success-link-to-home" class="btn bg-black text-white">{{ strings.home }}</a>
                    </div>
                    <!-- enter password -->
                    <div v-else-if="state == 'TODO'" class="holder w-full h-full relative flex flex-col justify-center">
                        <h1 class="h2 display-text mb-5 lg:mb-8">{{ strings.new }}</h1>
                        <PasswordInput @valid-password-input="completeRegistration" cypressLabel="password-reset" />
                    </div>
                    <!-- reset link expired -->
                    <div v-else class="w-full holder h-full relative flex flex-col justify-center">
                        <h1 class="h2 display-text mb-5 lg:mb-8">{{ strings.expired }}</h1>
                        <div class="px-4">
                            <p>{{ strings["exp-expired"] }}</p>
                            <router-link :to="{ name: 'password-lost' }" class="btn bg-black text-white inline-block my-6">
                                {{ strings["request-another"] }}
                            </router-link>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/ui/loadingSpinner";
import PasswordInput from "@/components/auth/passwordInput";
import text from "./passwordResetText";

export default {
    name: "PasswordReset",
    components: {
        PasswordInput,
        LoadingSpinner,
    },
    data() {
        return {
            state: "INVALID",
            email: "",
            loading: false,
            completed: false,
            password: "",
            strings: text,
        };
    },
    created() {
        this.loading = true;
        this.$api.auth
            .check_reset(this.$route.params.token)
            .then((response) => {
                this.state = response.data.state;
                this.email = response.data.email;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    },
    // validations
    methods: {
        completeRegistration(validPasswordValue) {
            this.loading = true;
            // eslint-disable-next-line global-require
            const bcrypt = require("bcryptjs");
            const salt = `$2a$10$KdtO7gxB1VPlolHaUBLZUu${this.email}`,
                password_hash = bcrypt.hashSync(validPasswordValue, salt);
            this.$api.auth
                .complete_reset(this.$route.params.token, password_hash)
                .then(() => {
                    console.log("success");
                    this.completed = true;
                    this.loading = false;
                })
                .catch(() => {
                    this.$toastSwal({
                        title: "There was a problem with the password input.",
                    });
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.holder {
    min-height: 222px;
}
</style>
