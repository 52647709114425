export default {
    loading: "Loading...",
    "already-reg": "Hmm, it looks like you've already registered.",
    login: "Go to login",
    thankyou: "Thank you for validating your email",
    "choose-pw": "Please choose a password:",
    expired: "Validation link expired",
    "re-signup": "Sorry, but this validation link does not exist or has expired. Please sign up again.",
    signup: "Sign up",
};
