export default {
    loading: "Loading...",
    success: "Success",
    "success-exp": "Your password has been reset",
    home: "Go to home",
    new: "Pick a new password",
    expired: "Reset link expired",
    "exp-expired": "Sorry, but this password reset link does not exist or has expired.",
    "request-another": "Request another reset link",
};
