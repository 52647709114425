<template>
    <div class="px-4">
        <div class="portal-input">
            <div class="relative mt-1 mb-3 rounded-full">
                <input
                    v-model.trim="password"
                    :data-cy="`${cypressLabel}-input`"
                    class="input w-full rounded-full appearance-none px-6 py-4 high-c-border"
                    type="password"
                    placeholder="New password"
                    autofocus
                    @input="delayTouch(v$.password)"
                    @keyup.enter="setNewPassword"
                />
                <div class="focus-border"></div>
            </div>
        </div>
        <button
            class="btn btn-larger bg-black text-white block w-full mt-4"
            :data-cy="`${cypressLabel}-button`"
            :class="{ 'opacity-90': v$.password.$invalid }"
            :disabled="v$.password.$invalid"
            @click="setNewPassword"
        >
            {{ strings["set-new"] }}
        </button>
        <div class="text-portal-sm mb-2">
            <div class="text-sm leading-none text-lighter mt-3" style="min-height: 2rem" :class="{ 'opacity-0': !v$.password.$error }">
                <p v-if="v$.password.$errors[0]">
                    <span v-if="v$.password.$errors[0].$validator === 'required'" :data-cy="`${cypressLabel}-missing`"> {{ strings.required }} </span>
                    <span v-else-if="v$.password.$errors[0].$validator === 'lengthOk'" :data-cy="`${cypressLabel}-length-error`">
                        {{ strings.length }}
                    </span>
                    <span v-else-if="v$.password.$errors[0].$validator === 'strongEnough'" :data-cy="`${cypressLabel}-complexity-error`">
                        {{ strings.complexity }}
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import debounce from "lodash.debounce";
import text from "./passwordInputText";

const strongEnough = (password) => {
        const zxcvbn = require("zxcvbn");
        const z = zxcvbn(password);
        return z.guesses_log10 > 6;
    },
    lengthOk = (password) => password.length > 8;

export default {
    name: "PasswordInput",
    setup() {
        // https://vuelidate-next.netlify.app/#getting-started-2
        return { v$: useVuelidate() };
    },
    props: {
        cypressLabel: {
            type: String,
            default: "password",
        },
    },
    data() {
        return {
            password: null,
            strings: text,
        };
    },
    validations: {
        password: {
            required,
            lengthOk,
            strongEnough,
        },
    },
    methods: {
        setNewPassword() {
            if (this.v$.$invalid) {
                // console.info("password does not meet validation criteria");
                this.v$.$touch();
                return;
            } else {
                // If the input is valid, we'll pass it up for handling
                this.$emit("valid-password-input", this.password);
            }
        },
        delayTouch: debounce((inputElement) => {
            if (!inputElement.$dirty) inputElement.$touch();
        }, 1000),
    },
};
</script>
